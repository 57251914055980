<template>
    <div class="error-box">
        <div
            class="py-12 d-flex flex-column align-items-center justify-content-center"
            style="min-height: 100vh"
        >
            <div class="text-center">
                <h1 class="error-title error--text">404</h1>
                <h3 class="text-uppercase error-subtitle">
                    No se ha encontrado el recurso solicitado !
                </h3>
                <p class="text-muted mt-4 mb-4">
                    Estás navegando a un recurso que no se encuentra en el
                    sistema o no tienes permisos para acceder a el
                </p>
                <b-button
                    v-if="token"
                    variant="primary"
                    size="md"
                    @click="$router.back()"
                    >Regresar</b-button
                >
                <b-button
                    v-else
                    variant="primary"
                    size="md"
                    @click="$router.push('/auth/login')"
                    >Iniciar Sesión</b-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { getItem } from '@/utils/localStorage'

export default {
    name: 'Error',
    data: () => ({
        token: null
    }),
    mounted() {
        this.token = getItem('TOKEN')
    }
}
</script>

<style lang="scss">
.error-box {
    min-height: 100vh;
    width: 100%;
    position: fixed;
}
.error-title {
    font-size: 8rem;
    font-weight: 900;
    line-height: 210px;
}

@media (max-width: 991px) {
    .error-title {
        font-size: 120px;
        line-height: 120px;
    }
}

@media (max-width: 767px) {
    .error-title {
        font-size: 40px;
        line-height: 40px;
    }
}
</style>
